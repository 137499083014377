'use client';

import Link from 'next/link';
import footer from './footer.webp';
import Image from 'next/image';
import { measure } from '../Analytics/Analytics';

const Footer = () => (
    <footer className="relative pt-8">
        <nav
            aria-label="Links voor meer informatie"
            className="md:absolute inset-x-0 bottom-4 lg:bottom-6 xl:bottom-8 w-full flex max-md:flex-col items-center justify-center gap-2 md:gap-4"
        >
            <FooterLink
                analyticsId="hetklokhuis.nl"
                label="Het Klokhuis"
                href="https://hetklokhuis.nl"
            />
            <FooterLink
                analyticsId="hetklokhuis_disclaimer"
                label="Disclaimer en privacy"
                href="https://hetklokhuis.nl/disclaimer"
            />
            <FooterLink
                analyticsId="hetklokhuis_ai"
                label="Het Klokhuis over AI"
                href="https://hetklokhuis.nl/ai"
            />
        </nav>
        <Image
            src={footer}
            alt="Heel veel mensen zitten in rijen achter computerschermen te kijken naar foto's van dezelfde hond. Eén persoon ziet er anders uit en kijkt naar een foto van een andere hond."
            className="w-full mx-auto"
            placeholder="blur"
            priority={false}
            quality={50}
            width={512}
            loading="lazy"
        />
    </footer>
);

const FooterLink = ({
    analyticsId,
    label,
    href,
}: {
    analyticsId: string;
    label: string;
    href: string;
}) => (
    <Link
        href={href}
        className="text-white bg-gradient-to-b from-secondary-800 to-secondary-900 px-6 py-2 rounded-full font-bold shadow-lg hover:underline"
        target="_blank"
        onClick={() => {
            measure(`footer_link_${analyticsId}`, 'navigation');
        }}
    >
        {label}
    </Link>
);

export default Footer;
